import './jquery.find-and-self.js'

import monomitter from 'monomitter'
import { trapper } from '@js/util/trapper'

class Menu {
  static defaults = {
    bodyToggleClass: 'toggled-menu',
    toggleClass: 'toggled',
    toggleContainer: '.toggle-container',
    toggleButton: '.toggle-button',
    closeButton: '.close-button',
  }

  constructor(element, options) {
    this.options = options
    let [triggerToggle, subscribeToggle] = monomitter()
    this.triggerToggle = triggerToggle

    this.$element = $(element)
    this.$body = $(document.body)
    this.$toggleContainer = this.$element.findAndSelf(options.toggleContainer)
    this.$toggleButton = this.$element.findAndSelf(options.toggleButton)
    this.$closeButton = this.$element.findAndSelf(options.closeButton)
    this.$context = options.context
      ? this.$element.findAndSelf(options.context)
      : this.$element

    if (typeof options.onToggle === 'function') {
      subscribeToggle(options.onToggle.bind(this))
    }

    this.escapeKeyListener = event => {
      if (event.key === 'Escape' && this.isOpen()) {
        event.stopPropagation()
        this.close()
      }
    }

    this.init()
  }

  isOpen() {
    return this.$toggleContainer.hasClass(this.options.toggleClass)
  }

  toggle() {
    if (this.isOpen()) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if (this.isOpen()) {
      return
    }

    this.untrap = trapper(this.$element.get(0))
    this.$body.addClass(this.options.bodyToggleClass)
    this.$toggleContainer.addClass(this.options.toggleClass)
    this.triggerToggle(true)
    $(document).on('keydown', this.escapeKeyListener)

    this.$toggleContainer.find('a').first().focus()
  }

  close() {
    if (!this.isOpen()) {
      return
    }

    this.untrap()
    this.$body.removeClass(this.options.bodyToggleClass)
    this.$toggleContainer.removeClass(this.options.toggleClass)
    this.triggerToggle(false)
    $(document).off('keydown', this.escapeKeyListener)
  }

  init() {
    // Toggle Button
    this.$toggleButton.click(() => {
      this.toggle()
    })

    // Close Button
    this.$closeButton.click(() => {
      this.close()
    })
  }
}

$.fn.Menu = function (option) {
  return this.each(function () {
    let $this = $(this)
    let data = $this.data('Menu')
    let options = $.extend(
      {},
      Menu.defaults,
      $this.data(),
      typeof option == 'object' && option,
    )

    if (!data) {
      $this.data('Menu', new Menu(this, options))
    } else {
      switch (option) {
        case 'toggle':
        case 'open':
        case 'close':
        case 'isOpen':
          return $this.data('Menu')[option]()
      }
    }
  })
}
