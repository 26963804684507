class MegaMenu {
  constructor(options) {
    let defaultOptions = {
      triggerSelector: 'a[data-mega-menu]',
      menuContainerSelector: '.mega-menu-container',
      menuSelector: '.mega-menu',
      headerSelector: 'header.main',
      activeClass: 'active',
      headerExtensionActiveClass: 'extension-active',
      triggerType: 'click',
      triggerActiveClass: 'mega-menu-active',
      onOpen: null,
    }

    this.options = { ...defaultOptions, ...options }
    this.currentMenu = null
    this.currentMenuId = null
    this.triggers = document.querySelectorAll(this.options.triggerSelector)
    this.menuContainer = document.querySelector(
      this.options.menuContainerSelector,
    )
    this.menus = document.querySelectorAll(this.options.menuSelector)
    this.listenToTrigger()
    this.closeOnBackgroundClick()
    this.header = document.querySelector(this.options.headerSelector)
  }

  listenToTrigger() {
    let { triggerType } = this.options
    this.triggers.forEach(trigger => {
      if ('hover' === triggerType) {
        trigger.addEventListener('mouseenter', e => {
          this.open(trigger.dataset.megaMenu)
          trigger.classList.add(this.options.triggerActiveClass)
          this.currentMenu.addEventListener(
            'mouseleave',
            e => {
              this.closeAll()
            },
            {
              once: true,
            },
          )
        })
      }

      if ('click' === triggerType) {
        trigger.addEventListener('click', e => {
          if (!trigger.isHot) {
            e.preventDefault()
            this.open(trigger.dataset.megaMenu)
            trigger.isHot = true
            trigger.classList.add(this.options.triggerActiveClass)
          }
        })
      }
    })
  }

  closeAll() {
    this.currentMenu = null
    this.menus.forEach(menu => menu.classList.remove(this.options.activeClass))
    this.triggers.forEach(trigger => {
      trigger.classList.remove(this.options.triggerActiveClass)
      trigger.isHot = false
    })
    this.menuContainer.classList.remove(this.options.activeClass)
    this.header.classList.remove(this.options.headerExtensionActiveClass)

    // this.menuContainer.classList.add('closing')
    // this.menuContainer.addEventListener('animationend', () => {
    //   this.menuContainer.classList.remove('closing')
    //   this.menuContainer.classList.remove(this.options.activeClass)
    //   this.header.classList.remove(this.options.headerExtensionActiveClass)
    // })
  }

  open(menuId) {
    this.closeAll()
    this.currentMenuId = menuId
    this.currentMenu = this.menuContainer.querySelector(
      `${this.options.menuContainerSelector} [data-mega-menu-is="${menuId}"]`,
    )
    this.currentMenu.classList.add(this.options.activeClass)
    this.menuContainer.classList.add(this.options.activeClass)
    this.header.classList.add(this.options.headerExtensionActiveClass)
    if (typeof this.options.onOpen === 'function') this.options.onOpen()
  }

  closeOnBackgroundClick() {
    this.menuContainer.addEventListener('click', e => {
      e.stopPropagation()
      if (e.target === this.menuContainer) {
        this.closeAll()
      }
    })
  }
}

export { MegaMenu }
