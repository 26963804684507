/**
 * Wait for a CSS transition to finish on an element
 *
 * @param {HTMLElement} element
 * @returns {Promise<void>}
 */
function waitTransition(element) {
  return new Promise(resolve => {
    const listener = event => {
      if (event.target === event.currentTarget) {
        element.removeEventListener('transitionend', listener)
        resolve()
      }
    }

    element.addEventListener('transitionend', listener)
  })
}

/**
 * @type {Toast|null}
 */
let previousToast = null

class Toast {
  /**
   * @type {number|null}
   */
  timeoutDestruction = null

  /**
   * @type {Promise<void>|null}
   */
  initialShow = null

  /**
   * @param {string} message The message to show in the toast
   * @param {object} options Options to the toast
   */
  constructor(
    message,
    {
      verticalPosition = 'bottom',
      horizontalPosition = 'right',
      transitionOrigin,
      show = true,
      timeout = 3000,
      container = document.body,
      type = '',
      edge = false,
      hideOnClick = true,
    } = {},
  ) {
    this.message = message
    this.verticalPosition = verticalPosition
    this.horizontalPosition = horizontalPosition
    this.transitionOrigin =
      transitionOrigin ??
      (verticalPosition === 'bottom'
        ? 'bottom'
        : verticalPosition === 'top'
        ? 'top'
        : horizontalPosition === 'left'
        ? 'left'
        : horizontalPosition === 'right'
        ? 'right'
        : 'top')
    this.container = container
    this.timeout = timeout
    this.hideOnClick = hideOnClick

    this.element = document.createElement('div')
    this.element.role = 'alert'
    this.element.classList.add(
      'toast',
      `toast--vertical-${this.verticalPosition}`,
      `toast--horizontal-${this.horizontalPosition}`,
      `toast--origin-${this.transitionOrigin}`,
    )

    if (type) {
      this.element.classList.add(`toast--${type}`)
    }

    this.element.innerHTML = this.message

    if (edge) {
      this.element.classList.add('toast--edge')
    }

    if (this.hideOnClick) {
      this.element.addEventListener('click', () => this.destroy())
    }

    this.container.appendChild(this.element)

    const previousToastDestruction = previousToast
      ? previousToast.destroy()
      : Promise.resolve()

    previousToast = this

    if (show) {
      this.initialShow = previousToastDestruction.then(
        () =>
          new Promise(resolve =>
            setTimeout(() => {
              requestAnimationFrame(() => this.show().then(resolve))
            }, 0),
          ),
      )

      this.initialShow.then(() => {
        this.initialShow = null
      })

      if (Number.isFinite(this.timeout) && this.timeout !== 0) {
        this.initialShow.then(() => {
          this.timeoutDestruction = setTimeout(() => {
            this.timeoutDestruction = null
            this.destroy()
          }, this.timeout)
        })
      }
    }
  }

  /**
   * @type {Promise<void>}
   */
  transitionPromise

  /**
   * Show the toast message
   *
   * @returns {Promise<void>} Indicate when show animation has finished
   */
  async show() {
    if (this.destroyed) {
      throw new Error('Toast has already been destroyed and can not be shown')
    }

    if (this.element.classList.contains('toast--shown')) {
      return this.transitionPromise || Promise.resolve()
    }

    if (this.transitionPromise) {
      await this.transitionPromise
    }

    this.transitionPromise = waitTransition(this.element)
    this.transitionPromise.then(() => {
      this.transitionPromise = null
    })

    this.element.classList.add('toast--shown')

    return this.transitionPromise
  }

  /**
   * Hide the toast message
   *
   * @returns {Promise<void>} Indicate when hide animation has finished
   */
  async hide() {
    // Cancel inherit timeout destruction if manually hiding
    if (this.timeoutDestruction !== null) {
      clearTimeout(this.timeoutDestruction)
      this.timeoutDestruction = null
    }

    if (this.destroyed) {
      throw new Error('Toast has already been destroyed and can not be hidden')
    }

    if (!this.element.classList.contains('toast--shown')) {
      return this.transitionPromise || Promise.resolve()
    }

    if (this.transitionPromise) {
      await this.transitionPromise
    }

    this.transitionPromise = waitTransition(this.element)
    this.transitionPromise.then(() => {
      this.transitionPromise = null
    })

    requestAnimationFrame(() => this.element.classList.remove('toast--shown'))

    return this.transitionPromise
  }

  /**
   * @type {boolean}
   */
  destroyed = false

  /**
   * Hide the toast and remove it from the DOM
   */
  async destroy() {
    if (this.destroyed) {
      throw new Error(
        'Toast has already been destroyed and can not be destroyed again',
      )
    }

    if (this.initialShow !== null) {
      await this.initialShow
    }

    const hidden = this.hide()
    this.destroyed = true
    if (this === previousToast) {
      previousToast = null
    }
    await hidden

    this.element.remove()
  }
}

/**
 * @param {string} message The message to show in the toast
 * @param {object} options Options to the toast
 */
function toast(message, options) {
  return new Toast(message, options)
}

for (const type of ['success', 'info', 'warning', 'error']) {
  toast[type] = (message, options) => toast(message, { type, ...options })
}

window.toast = toast
