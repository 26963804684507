import './modules/jquery.global-dollar.js'
import './modules/menu.js'
import './modules/toast.js'
import { MegaMenu } from './MegaMenu.js'
import Bowser from 'bowser'

import './common-elements/summary.js'

$(function () {
  /*******************************
   * Sticky Header
   ******************************/

  let stickyClass = 'with-sticky-header'

  function stickyHeader() {
    if (window.pageYOffset > 90) {
      document.body.classList.add(stickyClass)
    } else {
      document.body.classList.remove(stickyClass)
    }
  }

  window.addEventListener('scroll', stickyHeader, { passive: true })
  stickyHeader()

  /*******************************
   * Safari 14.0.3 and bellow versions
   ******************************/

  const browser = Bowser.getParser(window.navigator.userAgent)
  let isOlderSafari = browser.satisfies({
    macos: {
      safari: '<=14.0.3',
    },
  })
  if (isOlderSafari) {
    /*
     * If it is an older (but still widely used) version of safari
     * the header does not look properly.
     */
    document.querySelector('header.main.desktop').classList.add('safari-fix')
  }

  /*******************************
   * Mega Menu
   ******************************/

  let megaMenuDesktop = new MegaMenu({
    triggerType: 'click',
    triggerSelector: 'header.main.desktop a[data-mega-menu]',
    menuContainerSelector: 'header.main.desktop .mega-menu-container',
    menuSelector: 'header.main.desktop .mega-menu',
    headerSelector: 'header.main.desktop',
    onOpen: () => searchForm.close(),
  })

  let megaMenuMobile = new MegaMenu({
    triggerType: 'click',
    triggerSelector: 'header.main.mobile a[data-mega-menu]',
    menuContainerSelector: 'header.main.mobile .mega-menu-container',
    menuSelector: 'header.main.mobile .mega-menu',
    headerSelector: 'header.main.mobile',
  })

  const megaMenuBackButton = document.querySelectorAll(
    'header.main.mobile .mega-menu .back-button',
  )
  megaMenuBackButton.forEach(button =>
    button.addEventListener('click', () => megaMenuMobile.closeAll()),
  )

  const megaMenuCloseButton = document.querySelectorAll(
    'header.main.desktop .close-button',
  )
  megaMenuCloseButton.forEach(button =>
    button.addEventListener('click', () => megaMenuDesktop.closeAll()),
  )

  /*******************************
   * resizeEnd Event (https://stackoverflow.com/a/12692647/12404191)
   * Example usage:
   *
   * $(window).bind('resizeEnd', function() {
   * 		//do something, window hasn't changed size in 500ms
   * });
   ******************************/

  $(window).resize(function () {
    if (this.resizeEndTimeout) clearTimeout(this.resizeEndTimeout)
    this.resizeEndTimeout = setTimeout(function () {
      $(window).trigger('resizeEnd')
    }, 500)
  })

  /*******************************
   *  --vh CSS variable
   *  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   ******************************/

  function calculateVh() {
    var vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', vh + 'px')
  }

  calculateVh()

  $(window).on('resizeEnd', function () {
    calculateVh()
  })

  /*******************************
   *  --scrollbar-width CSS variable
   ******************************/

  function calculateScrollbarWidth() {
    var scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      scrollbarWidth + 'px',
    )
  }

  calculateScrollbarWidth()

  $(window).on('resizeEnd', function () {
    calculateScrollbarWidth()
  })

  /*******************************
   * Info bar
   ******************************/

  const infoBar = document.querySelector('#info-bar')
  if (infoBar) {
    const infoBarResizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const height = entry.contentRect.height
        document.body.style.setProperty('--header-offset-top', `${height}px`)
      }
    })
    infoBarResizeObserver.observe(infoBar)
  }

  /*******************************
   * Dismissables
   ******************************/

  const dismissableAttribute = 'data-dismissable'
  const dismissedClass = 'dismissed'
  const initializedClass = 'initialized'
  const dismissables = $(`[${dismissableAttribute}]`)

  dismissables.each((i, element) => {
    const $element = $(element)
    $element.addClass(initializedClass)

    const key = $element.attr(dismissableAttribute)
    const localStorageKey = `dismissable-dismissed__${key}`
    const dismissButton = $element.find('.dismiss-button')

    const isDismissed = Boolean(localStorage.getItem(localStorageKey))
    if (isDismissed) $element.addClass(dismissedClass)

    dismissButton.on('click', function () {
      localStorage.setItem(localStorageKey, true)
      $element.addClass(dismissedClass)
    })
  })
})
