/*******************************
 * If the wordpress included
 * jQuery is used, the following
 * must be set, to enable the
 * use of the $ var as jQuery.
 ******************************/

// In this project specifically, we need to define the dollar as a getter,
// because welante calls $.noConflict(), which removes the dollar from the global scope.
Object.defineProperty(window, '$', {
  get() {
    return jQuery
  },
  set() {
    // no-op
  },
})
